<template>
    <div class="wrap">
        <div class="content">
            <div class="user_info">
                <div class="left" :style="{ background: patientinfo.nursingClassName == '特级护理' ? '#FE5656' : patientinfo.nursingClassName == '一级护理' ? '#A18AEA' : patientinfo.nursingClassName == '二级护理' ? '#47ABFF' : patientinfo.nursingClassName == '三级护理' ? '#16B78C' : '#16B78C' }">{{ patientinfo.bedNo }}</div>
                <div class="right">
                    <div><span class="name" >{{patientinfo.name}}</span><span class="sex">{{patientinfo.sex}} {{patientinfo.age}}岁</span></div>
                    <div class="num">{{patientinfo.patientId}}</div>
                </div>
            </div>
            <div class="doctor_box">
                <div><span style="color:#999">费别：</span>{{ patientinfo.chargeType }}
                </div>
                <div><span style="color:#999">总费用：</span><span style="color:#F12C20">{{ patientinfo.totalCosts }}</span>
                </div>
            </div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">电话：</span>{{ patientinfo.phoneNumber }}</div>
            <div style="font-size: 16px;margin-top: 17px;"><span
                    style="color:#999;">现住址：</span>{{ patientinfo.mailingAddress }}</div>
            <div style="font-size: 19px;margin-top: 40px;font-weight: bold;">联系人信息</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">姓名：</span>{{patientinfo.nextOfKin}}</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">联系电话：</span>{{patientinfo.nextOfKinPhone}}</div>
            <div style="font-size: 19px;margin-top: 40px;font-weight: bold;">住院情况</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">门(急)诊诊断：</span>{{patientinfo.diagnosis}}</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院科室/病区：</span>{{patientinfo.deptAdmissionTo}}</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院病情：</span><span
                    style="color:#FE5656 ">{{patientinfo.patientStatusName}}</span></div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">护理等级：</span>{{patientinfo.nursingClassName}}</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院时间：</span>{{patientinfo.admissionDateTime}}
            </div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院天数：</span>{{patientinfo.inhosDays}}天</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">出院时间：</span>{{patientinfo.dischargeDateTime}}
            </div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">费用累计：</span> <span style="color:#1578f9">¥{{patientinfo.totalCosts}}</span>
            </div>
        </div>
        <!-- <div class="fybtn">费用累计 ¥{{patientinfo.totalCosts}}</div>
        <div class="czbtn">点击查看充值记录</div> -->
    </div>
</template>
<script>
export default {
    props:{
       patientinfo:{
          type:Object,
          default:{}
       } 
    },
    data() {
        return {

        }
    },
    mounted() {
        // 修改父元素样式
        document.getElementById('container').style.background = '#F6F6F6'
    },
    methods: {
        onClickLeft() {
            this.$router.back()
            // this.$router.push({ path: '/menu' })
        },
        onClickInfo() {
            this.$router.push({ path: '/jcInfo' })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background: #F6F6F6;
    // height: 100vh;
    height: 100%;
    // margin-bottom: -50px;
    .nav_bar {
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 50px 0px;
        text-align: center;
    }

    .content {
        background: #FFFFFF;
        box-shadow: 0px 2px 2px 0px rgb(7 2 14 / 6%);
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        // margin: -30px 20px 0;

        .user_info {
            display: flex;
            align-items: center;

            .left {
                width: 43px;
                height: 43px;
                background: #FE5656;
                border-radius: 10px;
                font-size: 21px;
                line-height: 43px;
                text-align: center;
                color: #FFFFFF;
                margin-right: 20px;
            }

            .right {
                margin-top: -5px;

                .name {
                    font-weight: bold;
                    color: #333333;
                    font-size: 19px;
                    margin-right: 20px;
                }

                .sex {
                    font-size: 13px;
                    // font-weight: bold;
                    color: #999999;
                    margin-top: 5px;
                }

                .num {
                    font-size: 16px;
                    // font-weight: bold;
                    color: #999999;
                    margin-top: 5px;
                }
            }

        }

        .doctor_box {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            margin-top: 25px;
            // padding-right: 40px;
            // padding: 0px 60px 16px 30px;
        }
    }

    .fybtn {
        margin: 20px 20px 10px 20px;
        padding: 10px 0px;
        background: #FFFFFF;
        border: 1px solid #1578F9;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #1578F9;
        line-height: 29px;
        text-align: center;
    }
    .czbtn {
        margin:0px 20px 50px 20px;
        padding: 10px 0px;
        background: #1578F9;
        border: 1px solid #1578F9;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        line-height: 29px;
        text-align: center;
    }
}

</style>